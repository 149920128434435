<template>
  <div class="navi navi-bold navi-hover navi-accent navi-active navi-link-rounded" :class="'navi-' + variant">
    <div v-for="(item, i) in items" :key="i" class="navi-item mb-2">
      <router-link v-slot="{ href, navigate }" custom :to="item.href">
        <a :href="href" class="navi-link py-4" :class="{ active: hasActiveParents(item.parents) }" @click="navigate">
          <span class="navi-icon mr-2">
            <span class="svg-icon svg-icon-2x">
              <inline-svg :src="item.icon" />
            </span>
          </span>
          <span class="navi-text font-size-lg">{{ item.label }}</span>
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    items: {
      type: Array,
      default: null
    },
    variant: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style></style>
