<template>
  <!--begin::Tiles Widget 8-->
  <div class="card card-custom gutter-b" :class="{ 'card-stretch': cardStretch }">
    <!-- card-stretch -->
    <!--begin::Header-->
    <div class="card-header">
      <div class="card-title">
        <div class="card-label">
          <div class="font-weight-bolder">Sua Carteira Snail</div>
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <OverlayLoader
        :is-submitting="!walletSnail.address"
        message="Sua Wallet é criada automaticamente após a validação dos seus documentos."
        :timing="true"
      >
        <!--begin::Items-->
        <div class="flex-grow-1 card-spacer">
          <div class="text-center mb-10">
            <qrcode-vue
              class="qrCode"
              :value="walletSnail.address"
              level="H"
              render-as="svg"
              foreground="#1775c6"
            ></qrcode-vue>
          </div>
          <!--begin::Item-->
          <div class="d-flex align-items-center justify-content-between mb-10">
            <div class="d-flex align-items-center mr-2">
              <div>
                <span class="font-size-h6 text-dark-75 font-weight-bolder">Data criação</span>
                <div class="font-size-sm text-muted font-weight-bold mt-1">Data da abertura da sua carteira</div>
              </div>
            </div>
            <div class="label label-primary label-inline font-weight-bold py-4 px-3 font-size-base">
              {{ walletSnail.created_at }}
            </div>
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="d-flex align-items-center justify-content-between mb-10">
            <div class="d-flex align-items-center mr-2">
              <div>
                <span class="font-size-h6 text-dark-75 font-weight-bolder">Email</span>
                <div class="font-size-sm text-muted font-weight-bold mt-1">Email associado a carteira</div>
              </div>
            </div>
            <div class="label label-primary label-inline font-weight-bold py-4 px-3 font-size-base">
              {{ walletSnail.email }}
            </div>
          </div>
          <!--end::Item-->
        </div>

        <!--end::Items-->
      </OverlayLoader>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Tiles Widget 8-->
</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import moment from 'moment';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';

export default {
  name: 'Widget4',
  components: {
    QrcodeVue,
    OverlayLoader
  },
  props: {
    cardStretch: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      walletSnail: {
        address: '',
        created_at: '--',
        email: '--'
      }
    };
  },
  computed: {
    ...mapGetters(['currentWalletSnail', 'currentUser'])
  },
  mounted() {
    if (this.currentWalletSnail) {
      this.walletSnail.address = this.currentWalletSnail.address;
      this.walletSnail.created_at = this.formatDateGet(this.currentWalletSnail.created_at);
      this.walletSnail.email = this.currentUser.email;
    }
  },
  methods: {
    formatDateGet(value) {
      return this.$moment(value).format('DD/MM/YYYY');
    }
  }
};
</script>
